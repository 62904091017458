import React, {useEffect} from "react"
import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"
// Image imports
import VideoProduction from "../../images/services/videoProduction.png"
import Commercials from "../../images/services/commercials.jpg"
import ContentMarketing from "../../images/services/contentMarketing.jpg"
import ProductionSupport from "../../images/services/productionSupport.png"
import Documentaries from "../../images/services/documentaries.jpg"
import Header from "../../components/Header"
import useMediaQuery from "../../hooks/useMediaQuery"
import { useTranslation } from "../../i18n"
import i18n from "i18next"
import { Helmet } from "react-helmet"

const services = () => {
	
	const { t } = useTranslation()
	const isDesktop = useMediaQuery("(min-width: 1200px)")
	useEffect(() => {
		i18n.changeLanguage("jp")
		  },
	[]);
	return (
		<div className="bg-tofuLightGrey text-tofuLightWhite font-syne">
			<Navbar />
			<Header
				title={t("title", { ns: "services" })}
				description={t("short-desc", { ns: "services" })}
			/>
			<Helmet>
				<title>TOFU Media</title>
				<meta name="description" content={"TOFU Mediaでは、東京をはじめとする国内・海外での、映像制作、外注、撮影、さらにはCM撮影にいたるまで、幅広いプロダクションサービスをご提供しております。機材やディレクター、プロデューサー、プロダクションクルーの手配も承っております。"} />
				<meta name="keywords" content={"東京 映像制作 映像制作 海外 映像制作 外注 映像撮影 CM制作 "} />
			</Helmet>
			<div className="flex flex-col-reverse lg:flex-row">
				<div className="mt-14 w-[90%] lg:w-[30rem] lg:mt-[22rem] mx-auto lg:ml-[18%]">
					<p className="font-black text-xl lg:text-3xl leading-[1.1]">
						{t("video-title1", { ns: "services" })} <br />{" "}
						{t("video-title2", { ns: "services" })}
					</p>
					<p className="break-words font-normal mt-4 lg:mt-8 leading-tight text-lg lg:text-xl">
						{t("video-desc1", { ns: "services" })}
					</p>
					<p className="break-words font-normal mt-4 lg:mt-8 leading-tight text-lg lg:text-xl">
						{t("video-desc2", { ns: "services" })}
					</p>
				</div>
				<img
					className="mt-32 lg:mt-36 overflow-hidden object-cover w-[90%] mx-auto lg:pl-8 lg:mr-28 lg:w-[47%] h-auto"
					src={VideoProduction}
					alt="Video production"
				/>
			</div>
			<div className="flex flex-col lg:flex-row">
				<img
					className="mt-32 lg:mt-36 w-[90%] object-cover lg:ml-[10rem] mx-auto lg:w-[35%]"
					src={Commercials}
					alt="Commercials"
				/>
				<div className="w-[90%] lg:w-[26rem] mt-14 lg:mt-[22rem] mx-auto xl:mx-[15%]">
					<p className="font-black text-xl lg:text-3xl leading-[1.1]">
						{t("commercials-title", { ns: "services" })}
					</p>
					<p className="break-words font-normal mt-4 lg:mt-8 leading-tight text-lg lg:text-xl">
						{t("commercials-desc-1", { ns: "services" })}
					</p>
					<p className="break-words font-normal mt-4 lg:mt-8 leading-tight text-lg lg:text-xl">
						{t("commercials-desc-2", { ns: "services" })}
					</p>
					<p className="break-words font-normal mt-4 lg:mt-8 leading-tight text-lg lg:text-xl">
						{t("commercials-desc-3", { ns: "services" })}
					</p>
				</div>
			</div>
			<div className="flex flex-col-reverse lg:flex-row">
				<div className="w-[90%] lg:w-[28rem] mt-14 lg:mt-[18rem] mx-auto lg:mx-[9%]">
					<p className="font-black text-xl lg:text-3xl leading-[1.1] break-words">
						{/* {t("documentary-title", { ns: "services" })} */}
						{isDesktop ? (
							<span>
								{t("documentary-title-break1", { ns: "services" })} <br /> {t("documentary-title-break2", { ns: "services" })}
							</span>
						) : (
							<span>{t("documentary-title", { ns: "services" })}</span>
						)}
					</p>
					<p className="break-words font-normal mt-4 lg:mt-8 leading-tight text-lg lg:text-xl">
						{t("documentary-desc-1", { ns: "services" })}
					</p>
					<p className="break-words font-normal mt-4 lg:mt-8 leading-tight text-lg lg:text-xl">
						{t("documentary-desc-2", { ns: "services" })}
					</p>
					<p className="break-words font-normal mt-4 lg:mt-8 leading-tight text-lg lg:text-xl">
						{t("documentary-desc-3", { ns: "services" })}
					</p>
				</div>
				<img
					className="mt-32 lg:mt-36 overflow-hidden object-cover w-[90%] mx-auto lg:mr-28 lg:w-[50%] h-auto"
					src={Documentaries}
					alt="Documentiaries"
				/>
			</div>
			<div className="flex flex-col lg:flex-row">
				<img
					className="mt-32 lg:mt-36 w-[90%] object-cover lg:ml-[10rem] mx-auto lg:w-[35%]"
					src={ContentMarketing}
					alt="Content marketing"
				/>
				<div className="w-[90%] lg:w-[26rem] mt-14 lg:mt-[22rem] mx-auto xl:mx-[15%]">
					<p className="font-black text-xl lg:text-3xl leading-[1.1]">
						{t("content-title", { ns: "services" })}
					</p>
					<p className="break-words font-normal mt-4 lg:mt-8 leading-tight text-lg lg:text-xl">
						{t("content-desc-1", { ns: "services" })}
					</p>
					<p className="break-words font-normal mt-4 lg:mt-8 leading-tight text-lg lg:text-xl">
						{t("content-desc-2", { ns: "services" })}
					</p>
				</div>
			</div>
			<div className="flex flex-col-reverse lg:flex-row">
				<div className="w-[90%] lg:w-[28rem] mt-14 lg:mt-[18rem] mx-auto lg:mx-[9%]">
					<p className="font-black text-xl lg:text-3xl leading-[1.1]">
						{t("production-title", { ns: "services" })}
					</p>
					<p className="break-words font-normal mt-4 lg:mt-8 leading-tight text-lg lg:text-xl">
						{t("production-desc-1", { ns: "services" })}
					</p>
					<p className="break-words font-normal mt-4 lg:mt-8 leading-tight text-lg lg:text-xl">
						{t("production-desc-2", { ns: "services" })}
					</p>
					<p className="break-words font-normal mt-4 lg:mt-8 leading-tight text-lg lg:text-xl">
						{t("production-desc-3", { ns: "services" })}
					</p>
				</div>
				<img
					className="mt-32 lg:mt-36 overflow-hidden object-cover w-[90%] mx-auto lg:mr-28 lg:w-[50%] h-auto"
					src={ProductionSupport}
					alt="Production support"
				/>
			</div>
			<Footer />
		</div>
	)
}

export default services
